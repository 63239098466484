/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'
import { firstSortItems, formatDateInput, getLanguage, prioritizeCountries } from '../../helpers'
import { useForm, Controller } from 'react-hook-form'
import { type InputsAddNewClient, type Client, type Hotel } from '../../types'
import Loader from '../global/Loader'
import FadeAnimation from '../global/FadeAnimation'

import Select from 'react-select'
import { useLingui } from '@lingui/react'
import countries from 'i18n-iso-countries'

interface FormAddNewMemberProps {
    oneClientDetail?: Client
    updateForm?: boolean
    hnadleSubmitFormData: (data: InputsAddNewClient) => void
    hotels: Hotel[]
}

const FormAddNewMember: React.FC<FormAddNewMemberProps> = (props: FormAddNewMemberProps) => {
    const { oneClientDetail, updateForm, hnadleSubmitFormData } = props
    const [loading] = useState(false)

    const orderHotels = useMemo(() => {
        // set first hotel "Grand hotel Permon"
        const orderdHotels = firstSortItems(props.hotels, 'Grand hotel Permon')
        return orderdHotels
    }
    , [props.hotels])

    const lingui = useLingui()

    const countriesOptions = useMemo(() => {
        if (lingui.i18n.locale !== '') {
            countries.registerLocale(require('i18n-iso-countries/langs/' + lingui.i18n.locale + '.json'))
        }

        const cntrs = countries.getNames(lingui.i18n.locale)
        const vals = Object.entries(cntrs).map((val, i) => {
            return {
                value: val[0],
                label: val[1]
            }
        })

        const orderedVals = prioritizeCountries(vals, ['Slovensko', 'Česko'])

        return orderedVals
    }, [lingui.i18n.locale])

    const languages = getLanguage()
    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors }
    } = useForm<InputsAddNewClient >({
        defaultValues: {
            name: oneClientDetail?.name,
            email: oneClientDetail?.email,
            sex: oneClientDetail?.sex,
            phone_number: oneClientDetail?.phone_number,
            card_number: oneClientDetail?.card_number,
            birth_date: formatDateInput(oneClientDetail?.birth_date ?? ''),
            address: {
                street: oneClientDetail?.address.street,
                city: oneClientDetail?.address.city,
                postcode: oneClientDetail?.address.postcode,
                state: oneClientDetail?.address.state
            },
            comment: oneClientDetail?.comment
        }
    })

    const sex = watch('sex')

    const hotelName = useMemo(() => {
        return props.hotels.find((hotel) => {
            return hotel.id !== undefined && hotel.id === oneClientDetail?.hotel_id
        })
    }, [props.hotels, oneClientDetail])

    const language = languages.find(l => l.short === oneClientDetail?.preferred_languange)

    const selectStyles = {
        control: (base: any, state: any) => ({
            ...base,
            fontSize: '11px',
            height: '40px',
            borderRadius: '10px',
            border: state.isFocused === true ? '1px solid #6B6145 !important' : '0.4px solid #6B6145 !important',
            boxShadow: state.isFocused === true ? '0 0 0 1px #6B6145 !important' : 'none',
            transition: 'all 0.5s ease-in-out'
        }),
        multiValue: (base: any) => ({
            ...base,
            backgroundColor: 'blue',
            color: 'white',
            fontSize: '11px important'
        }),
        option: (base: any, { isFocused, isSelected }: any) => ({
            ...base,
            fontSize: '11px',
            padding: '0px 10px',
            color: isFocused === true || isSelected === true ? 'white' : '#6B6145'
        })
    }

    return (
        <>
            <form onSubmit={handleSubmit(hnadleSubmitFormData)}>
                <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row">
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="name" className="sm-font-b md:inline hidden">
                            <Trans>Meno a priezvisko*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('name', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    }
                                })}
                                type="text"
                                placeholder={t`Meno a priezvisko*`}
                                name="name"
                                id="name"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.name != null) && (
                                <FadeAnimation type="up" key={errors.name.message}>
                                    <div className="error-text">
                                        {errors.name.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="birth_date" className="sm-font-b md:inline hidden">
                            <Trans>Dátum narodenia*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <Controller
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    },
                                    validate: {
                                        isFourDigits: value => {
                                            const year = new Date(value).getFullYear()
                                            return (year >= 1000 && year <= 9999) || t`Zadajte rok v tvare štyroch číslic`
                                        },
                                        isCurrentOrPastYear: value => {
                                            const currentYear = new Date()
                                            const year = new Date(value)
                                            return year <= currentYear || t`Zadali ste neplatný dátum`
                                        }
                                    }
                                }}

                                name="birth_date"
                                defaultValue={oneClientDetail?.birth_date}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="date"
                                        name="birth_date"
                                        placeholder={t`Dátum narodenia*`}
                                        id="birth_date"
                                        className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                                    />
                                )}
                            />
                            {(errors.birth_date != null) && (
                                <FadeAnimation type="up" key={errors.birth_date.message}>
                                    <div className="error-text  text-[8px]">
                                        {errors.birth_date.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>

                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="email" className="sm-font-b md:inline hidden">
                            <Trans>Email*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t`Zadajte platnú e-mailovú adresu`
                                    }
                                })}
                                type="email"
                                name="email"
                                placeholder={t`Email*`}
                                id="email"
                                autoComplete="on"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.email != null) && (
                                <FadeAnimation type="up" key={errors.email.message}>
                                    <div className="error-text">
                                        {errors.email.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-baseline md:gap-[21px] text-left lg:px-[46.5px] md:flex-row mt-3">
                    <div className='flex flex-col  md:w-1/3 relative'>
                        <legend className="sm-font-b xs:mr-7 mr-4">
                            <Trans>Pohlavie*</Trans>
                        </legend>
                        <div className="relative flex items-start mt-[6px] h-[40px]">
                            <div className="flex h-6 items-center mr-7">
                                <Controller
                                    control={control}
                                    name="sex"
                                    defaultValue=""
                                    rules={{ required: t`Vyberte jednu z možností.` }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                id="male"
                                                type="checkbox"
                                                checked={field.value === 'male'}
                                                onChange={() => { setValue('sex', 'male') }}
                                                className="input-transition input-checkbox"
                                            />
                                            <label htmlFor="male" className="font-basic ml-[10px]">
                                                <Trans>Muž</Trans>
                                            </label>
                                        </>
                                    )}
                                />
                            </div>
                            <div className="flex h-6 items-center">
                                <Controller
                                    control={control}
                                    name="sex"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <input
                                                id="female"
                                                type="checkbox"
                                                checked={field.value === 'female'}
                                                onChange={() => { setValue('sex', 'female') }}
                                                className="input-transition input-checkbox"
                                            />
                                            <label htmlFor="female" className="font-basic ml-[10px]">
                                                <Trans>Žena</Trans>
                                            </label>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='absolute w-full bottom-4'>
                            {sex === '' && (
                                <FadeAnimation type="up" key={errors.sex?.message}>
                                    <div className="error-text">
                                        {errors.sex?.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="card_number" className="sm-font-b md:inline hidden">
                            <Trans>Číslo členskej karty</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('card_number')}
                                type="text"
                                placeholder={t`Číslo členskej karty`}
                                name="card_number"
                                id="card_number"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="phone_number" className="sm-font-b md:inline hidden">
                            <Trans>Telefónne číslo</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('phone_number', {
                                    pattern: {
                                        value: /^\+?(\d{1,3})?[-\s]?(\(\d{1,3}\))?[-\s]?\d{1,4}([-/\s]?\d{1,4}){1,3}$/,
                                        message: 'Zadajte číslo v medzinárodnom formáte'
                                    }
                                })}
                                type='text'
                                onKeyPress={(event) => {
                                    if (!/[0-9+\s()-]/.test(event.key)) {
                                        event.preventDefault()
                                    }
                                }}
                                placeholder='Telefónne číslo'
                                name="phone_number"
                                id="phone_number"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.phone_number != null) && (
                                <FadeAnimation type="up" key={errors.phone_number.message}>
                                    <div className="error-text">
                                        {errors.phone_number.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>

                    </div>
                </div>
                <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row mt-[15px]">
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="address.street" className="sm-font-b md:inline hidden">
                            <Trans>Ulica*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('address.street', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    }
                                })}
                                type="text"
                                name="address.street"
                                placeholder={t`Ulica*`}
                                id="street"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.address?.street != null) && (
                                <FadeAnimation type="up" key={errors.address.street.message}>
                                    <div className="error-text">
                                        {errors.address.street.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>

                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="address.city" className="sm-font-b md:inline hidden">
                            <Trans>Mesto*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('address.city', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    }
                                })}
                                type="text"
                                name="address.city"
                                placeholder={t`Mesto*`}
                                id="city"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.address?.city != null) && (
                                <FadeAnimation type="up" key={errors.address.city.message}>
                                    <div className="error-text">
                                        {errors.address.city.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="address.postcode" className="sm-font-b md:inline hidden">
                            <Trans>PSČ*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('address.postcode', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    },
                                    minLength: {
                                        value: 5,
                                        message: t`Minimálna dĺžka je 5 číslic`
                                    },
                                    maxLength: {
                                        value: 5,
                                        message: t`Maximálna dĺžka je 5 číslic`
                                    },
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: t`Vložte prosím len čísla`
                                    }
                                })}
                                type="number"
                                name="address.postcode"
                                placeholder={t`PSČ*`}
                                id="zipCode"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.address?.postcode != null) && (
                                <FadeAnimation type="up" key={errors.address.postcode.message}>
                                    <div className="error-text">
                                        {errors.address.postcode.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row ">
                    <div className="md:w-1/3 w-full pb-3 select-state">
                        <label htmlFor="address.state" className="sm-font-b md:inline hidden">
                            <Trans>Štát*</Trans>
                        </label>
                        <div className="mt-[6px] ">
                            <Select
                                {...register('address.state', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`

                                    }
                                })}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#4f46e5',
                                        primary: '#4f46e5'
                                    }
                                })}
                                defaultValue= {updateForm === true ? { label: oneClientDetail?.address.state ?? '' } : null}
                                placeholder={t`Vyberte štát*`}
                                options={countriesOptions}
                                isSearchable={true}
                                styles={selectStyles}
                                onChange={val => {
                                    if (val != null) {
                                        setValue('address.state', val.label)
                                    }
                                }}
                            />
                            {(errors.address?.state !== undefined) && (
                                <FadeAnimation type="up" key={errors.address.state.message}>
                                    <div className="error-text">
                                        {errors.address.state.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>

                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="hotel_id" className="sm-font-b md:inline hidden">
                            <Trans>Zariadenie*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <select
                                {...register('hotel_id', {
                                    required: {
                                        value: true,
                                        message: t`Vyberte jednu z možností.`
                                    }
                                })}
                                id="hotel"
                                name="hotel_id"
                                className={`input-form py-[5px] input-focus input-transition font-basic font-montserrat-bold md:font-montserrat-regular 
                                ${(updateForm === true) ? 'font-montserrat-regular' : 'font-montserrat-bold md:font-montserrat-regular'}`}
                                defaultValue={hotelName?.id ?? ''}
                            >
                                <option value={''} disabled hidden> <Trans>Vyberte zadiadenie*</Trans></option>
                                {orderHotels.map(hotel => (
                                    <option
                                        key={hotel.id}
                                        value={hotel.id}
                                        disabled={hotel.deleted_at !== null}
                                    >
                                        {hotel.name}
                                    </option>
                                ))}
                            </select>
                            {(errors.hotel_id != null) && (
                                <FadeAnimation type="up" key={errors.hotel_id.message}>
                                    <div className="error-text">
                                        {errors.hotel_id.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                    <div className="md:w-1/3 w-full pb-3">
                        <label htmlFor="preferred_languange" className="sm-font-b md:inline hidden">
                            <Trans>Preferovaný jazyk*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <select
                                {...register('preferred_languange', {
                                    required: {
                                        value: true,
                                        message: t`Vyberte jednu z možností.`
                                    }
                                })}
                                id="languange"
                                name="preferred_languange"
                                className={`input-form py-[5px] input-focus input-transition font-basic font-montserrat-bold md:font-montserrat-regular 
                                ${(updateForm === true) ? 'font-montserrat-regular' : 'font-montserrat-bold md:font-montserrat-regular'}`}
                                defaultValue={oneClientDetail?.preferred_languange}
                            >
                                <option value={''} disabled hidden>{language?.name ?? t`Vyberte jazyk*`}</option>
                                {languages.map(l => (
                                    <option
                                        value={l.short}
                                        key={l.short}
                                    >
                                        {l.name}
                                    </option>
                                ))}
                            </select>
                            {(errors.preferred_languange != null) && (
                                <FadeAnimation type="up" key={errors.preferred_languange.message}>
                                    <div className="error-text">
                                        {errors.preferred_languange.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center  text-left lg:px-12">
                    <div className="w-full">
                        <label htmlFor="comment" className="sm-font-b md:inline hidden">
                            <Trans>Poznámka</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <textarea
                                {...register('comment')}
                                name="comment"
                                placeholder={t`Poznámka`}
                                id="note"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                        </div>
                    </div>
                </div>
                <button
                    type="submit"
                    className='btn btn-red btn-m block mx-auto mt-5  w-max-[189px]'
                >
                    {(updateForm === true) ? t`Upraviť údaje` : t`Pridať nového člena`}
                </button>
            </form>
            {loading &&
                <div className='flex justify-center py-4'>
                    <Loader/>
                </div>
            }
        </>
    )
}

export default FormAddNewMember
