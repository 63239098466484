import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { type RootState } from '../../redux/store'
import PageLogin from '../../pages/PageLogin'
import { Outlet } from 'react-router-dom'
import Header from '../header/Header'
import SideBar from '../global/SideBar'
import Languages from '../global/Languages'

interface PrivateRouteProps {
    path?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = () => {
    const loginUser = useSelector((state: RootState) => state.login.user)
    const [toggleMenu, setToggleMenu] = useState(true)

    const handleToggleMenu = (): void => {
        setToggleMenu(!toggleMenu)
    }

    if (loginUser !== null) {
        return (
            <div >
                <Header handleToggleMenu={handleToggleMenu} toggleMenu={toggleMenu}/>
                <div className='lg-max:px-[30px] '>
                    <div className="container bg-white lg:rounded-20 flex  lg:shadow-lg-shadow relative rounded-[10px]">
                        <div className={`left-sidebar ${toggleMenu ? '' : 'show '}`}>
                            <SideBar handleToggleMenu={handleToggleMenu} />
                        </div>
                        <div className='w-full flex flex-col relative custom-height'>
                            <Outlet />
                        </div>
                    </div>
                </div>
                <Languages/>
            </div>
        )
    }

    return <PageLogin />
}

export default PrivateRoute
