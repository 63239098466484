/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { type ReactNode, useMemo, useState, useEffect } from 'react'
import type { Client, InputsAddNewClient, ModalWindowData } from '../../types'
import ModalWindow from '../global/ModalWindow'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import IconCross from '../icons/IconCross'
import { DetailFooter } from './DetailFooter'
import DetailHeaderMember from './DetailHeaderMember'
import DetailInfoItemMember from './DetailInfoItemMember'
import { useSelector, useDispatch } from 'react-redux'
import { type RootState, type AppDispatch } from '../../redux/store'
import Loader from '../global/Loader'
import ModalWindowForm from '../global/ModalWindowForm'
import FormAddNewMember from '../forms/FormAddNewMember'
import DetailMemberVoucher from './DetailMemberVoucher'
import DetailMemberLogs from './DetailMemberLogs'
import PageLogs from '../../pages/PageLogs'
import { fetchClientSystemLog, fetchVocherSystemLog } from '../../redux/thunks/systemLogThunks'
import { selectClientSystemLog, selectVoucherSystemLog } from '../../redux/slices/systemLogSlice'
import IconWarning from '../icons/IconWarning'
import { resetUpdateSuccess, setFormData } from '../../redux/slices/clientSlice'
import { fetchAllVouchers } from '../../redux/thunks/voucherThunks'
import { deleteClient, fetchUpdateClient, restoreClient } from '../../redux/thunks/clientThunks'
import { prepateFormData } from '../../helpers'
import axios from 'axios'
import IconSuccess from '../icons/IconSuccess'
import DetailGenerateVoucher from './DetailGenerateVoucher'
import { fetchAllHotels } from '../../redux/thunks/hotelThunks'

interface DetailComponentMemberProps {
    children?: ReactNode
}

const DetailComponentMember: React.FC<DetailComponentMemberProps> = (props: DetailComponentMemberProps) => {
    const systemLogUser = useSelector(selectClientSystemLog)
    const systemVoherLog = useSelector(selectVoucherSystemLog)
    const updateSuccess = useSelector((state: RootState) => state.client.updateSuccess)
    const dispatch = useDispatch<AppDispatch>()
    const vouchers = useSelector((state: RootState) => state.voucher.allData)
    const formDataUpdate = useSelector((state: RootState) => state.client.formData)
    const clientName = useSelector((state: RootState) => state.client.clientData)
    const error = useSelector((state: RootState) => state.client.error)
    const hotels = useSelector((state: RootState) => state.hotel.data)

    const [oneClientDetail, setOneClientDetail] = useState<Client>()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openModalForm, setOpenModalForm] = useState(false)
    const [updateForm, setUpdateForm] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const navigate = useNavigate()
    const [openLog, setOpenLog] = useState(false)

    const { id } = useParams<{ 'id': string | undefined }>()
    const numericId = id !== undefined ? parseInt(id) : 0

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response = await axios.get(`/api/client/${numericId}`)
                setOneClientDetail(response.data.data)
            } catch (error) {
            }
        }
        void fetchData()
    }, [numericId])

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    const isDeleted = oneClientDetail?.deleted_at
    const textDeleteButton = oneClientDetail?.deleted_at === null
        ? t`Deaktivovať člena`
        : t`Aktivovať člena`
    const textTileDetail = t`Informácie o členovi`
    const buttonIcon = 'user-edit.svg'
    const titleModalForm = t`Upraviť údaje`
    const subtitleModalForm = t`Vyplňte údaje`

    const closeWindow = (): void => {
        setOpen(false)
    }

    const handleOpenFooter = (): void => {
        setOpen(true)
        if (oneClientDetail?.deleted_at === null) {
            setDataModal({
                title: t`Deaktivovať člena`,
                subtitle: t`Ste si istý, že chcete deaktivovať tohto člena? Akcia sa nedá vrátiť späť.`,
                textButton: t`Deaktivovať`,
                icon: <IconWarning />,
                action: confirmDelete
            })
        } else {
            setDataModal({
                title: t`Aktivovať člena`,
                subtitle: t`Ste si istý, že chcete aktivovať tohto člena? Akcia sa nedá vrátiť späť.`,
                textButton: t`Aktivovať`,
                icon: <IconSuccess />,
                action: confirmActivate
            })
        }
    }

    const confirmDelete = (): void => {
        setDataModal({
            title: t`Potvrdenie vykonania akcie`,
            subtitle: t`Kliknite na deaktivovať pre potvrdenie akcie.`,
            textButton: t`Deaktivovať`,
            icon: <IconWarning />,
            action: () => {
                void dispatch(deleteClient(numericId))
                    .then(() => {
                        navigate('/members')
                    })
            }
        })
    }

    // set funtion on cofrimActivate
    const confirmActivate = (): void => {
        setDataModal({
            title: t`Potvrdenie vykonania akcie`,
            subtitle: t`Kliknite na aktivovať pre potvrdenie akcie.`,
            textButton: t`Aktivovať`,
            icon: <IconSuccess />,
            action: () => {
                void dispatch(restoreClient(numericId))
                    .then(() => {
                        navigate('/members')
                    })
            }
        })
    }

    const handleOpenLog = (value: boolean): void => { setOpenLog(value) }

    const foundVoucherNumbers = useMemo(() => {
        if (vouchers !== undefined) {
            return vouchers
                .filter((voucher) => voucher.client_id !== undefined && voucher.client_id === numericId)
                .map((voucher) => ({ number: voucher.number, state: voucher.state }))
        }
        return []
    }, [numericId, vouchers])

    const handleOpenModalForm = (): void => {
        setOpenModalForm(true)
        setUpdateForm(true)
    }

    useEffect(() => {
        void dispatch(fetchAllVouchers())
    }, [dispatch])

    useEffect(() => {
        void dispatch(fetchClientSystemLog(numericId))
    }, [numericId, openLog, dispatch])

    useEffect(() => {
        void dispatch(fetchVocherSystemLog(numericId))
    }, [numericId, openLog, dispatch])

    useEffect(() => {
        setOpenLog(false)
    }, [oneClientDetail])

    const resendUpdateFormData = (): void => {
        const formData = formDataUpdate
        if (formData === null) {
            return
        }
        const form = prepateFormData(formData)
        form.append('allow_duplicate_email', 'true')

        setLoading(true)

        void dispatch(fetchUpdateClient({ formData: form, clientId: numericId })).then((response: any) => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!updateSuccess) {
            if (error === 'email_exists') {
                setOpen(true)
                setDataModal(
                    {
                        title: t`E-mail v databáze existuje`,
                        subtitle: <>
                            {t`Zadaný email už existuje pre člena`}
                            <strong className='underline'>
                                <a
                                    target='_blank'
                                    href={`/members/${clientName.id}`}
                                    rel="noreferrer">
                                    {clientName.name}
                                </a>
                            </strong>
                            {t`. Prajete si povoliť duplicitnú registráciu?`}
                        </>,
                        textButton: t`Potvrdiť`,
                        icon: <IconWarning />,
                        action: resendUpdateFormData
                    }
                )
            } else {
                if (updateSuccess) {
                    setOpen(true)
                    setDataModal(
                        {
                            title: t`Chyba`,
                            subtitle: t`Nastala chyba pri ukladaní údajov`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning />,
                            action: () => { setOpen(false) }
                        }
                    )
                }
            }
        }
    }, [updateSuccess])

    useEffect(() => {
        dispatch(resetUpdateSuccess())
    }, [dispatch])

    const hnadleSubmitFormData = (data: InputsAddNewClient): void => {
        dispatch(setFormData(data))
        const form = prepateFormData(data)

        setLoading(true)

        if (updateForm) {
            void dispatch(fetchUpdateClient({ formData: form, clientId: numericId }))
                .then((response: any) => {
                })
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <>
                <ModalWindow
                    open={open}
                    setOpen={setOpen}
                    dataModalWindow={dataModal}
                    loading={false}
                    closeWindow={closeWindow}
                />
                <ModalWindowForm
                    open={openModalForm}
                    setOpen={setOpenModalForm}
                    titleModalForm={titleModalForm}
                    subtitleModalForm={subtitleModalForm}
                    myFormComponent={
                        <FormAddNewMember
                            oneClientDetail={oneClientDetail}
                            updateForm={updateForm}
                            hnadleSubmitFormData={hnadleSubmitFormData}
                            hotels={hotels}
                        />
                    }
                />
                <NavLink
                    className='flex justify-end mr-3'
                    title={t`Zatvoriť`}
                    to={'/members'}
                >
                    <IconCross />
                </NavLink>
                {
                    (oneClientDetail != null)
                        ? (
                            <>
                                <DetailHeaderMember
                                    oneItemDetail={oneClientDetail}
                                    buttonIcon={buttonIcon}
                                    handleOpenModalForm={handleOpenModalForm}
                                    openLog={openLog}
                                />
                                {openLog
                                    ? <PageLogs
                                        handleOpenLog={handleOpenLog}
                                        vocherLog={systemVoherLog}
                                        systemLog={systemLogUser} />
                                    : <>
                                        <DetailGenerateVoucher
                                            foundVoucherNumbers={foundVoucherNumbers}
                                            numericId={numericId}
                                        />
                                        <DetailMemberVoucher
                                            foundVoucherNumbers={foundVoucherNumbers}
                                        />
                                        <DetailInfoItemMember
                                            oneItemDetail={oneClientDetail}
                                            textTileDetail={textTileDetail}
                                        />
                                        <div className='mt-5 w-fit'>
                                            <DetailMemberLogs handleOpenLog={handleOpenLog} />
                                            <DetailFooter
                                                handleOpenFooter={handleOpenFooter}
                                                textDeleteButton={textDeleteButton}
                                                isDeleted={isDeleted}
                                            />
                                        </div>
                                    </>
                                }
                            </>
                        )
                        : <div className='flex justify-center items-center pt-10'>
                            <p className="font-basic-black leading-[18px] font-montserrat-bold">
                                <Trans>Detail daného uživateľa nexistuje</Trans>
                            </p>
                        </div>
                }
            </>
        </>
    )
}

export default DetailComponentMember
