import React, { useEffect, useState } from 'react'
import FormCodeVerification from '../components/forms/FormCodeVerification'
import { Trans, t } from '@lingui/macro'
import FormAddNewMember from '../components/forms/FormAddNewMember'
import { type ModalWindowData, type InputsAddNewClient } from '../types'
import IconWarning from '../components/icons/IconWarning'
import IconSuccess from '../components/icons/IconSuccess'
import ModalWindow from '../components/global/ModalWindow'
import { fetchCreateClient } from '../redux/thunks/clientThunks'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState, type AppDispatch } from '../redux/store'
import Loader from '../components/global/Loader'
import { useNavigate } from 'react-router-dom'
import { fetchAllHotels } from '../redux/thunks/hotelThunks'

const PageHome: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const [disabledCrossIcon, setDisabledCrossIcon] = useState(true)
    const [disabledButton, setDisabledButton] = useState(false)
    const navigate = useNavigate()

    const closeWindow = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    const hnadleSubmitFormData = (data: InputsAddNewClient): void => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('birth_date', data.birth_date)
        formData.append('email', data.email)
        formData.append('sex', data.sex)
        formData.append('address[street]', data.address.street)
        formData.append('address[city]', data.address.city)
        formData.append('address[postcode]', data.address.postcode)
        formData.append('address[state]', data.address.state)
        formData.append('hotel_id', data.hotel_id)
        formData.append('preferred_languange', data.preferred_languange)
        formData.append('comment', data.comment ?? '')
        formData.append('card_number', data.card_number)
        formData.append('phone_number', data.phone_number)

        setLoading(true)

        const closeWindowNavigate = (): void => {
            navigate('/members')
        }

        const resendFormData = (): void => {
            formData.append('allow_duplicate_email', 'true')
            setLoading(true)
            setDisabledButton(true)

            void dispatch(fetchCreateClient(formData)).then((response: any) => {
                setDisabledButton(false)
                setDisabledCrossIcon(true)
                setLoading(false)
                if (response.payload !== undefined && response.payload !== null && response.error === undefined) {
                    setDataModal({
                        title: t`Prdanie nového člena`,
                        subtitle: t`Nový člen bol úspešne pridaný do systému.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconSuccess />,
                        action: closeWindowNavigate
                    })
                    setOpen(true)
                } else {
                    setDataModal({
                        title: t`Chyba`,
                        subtitle: t`Nastala chyba pri pridávaní nového člena.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                    setOpen(true)
                }
            })
        }

        void dispatch(fetchCreateClient(formData))
            .then((response: any) => {
                setOpen(true)
                setLoading(false)
                setDisabledButton(false)
                if (response.payload.existing_email === true) {
                    setDisabledCrossIcon(false)
                    setDataModal({
                        title: t`E-mail v databáze existuje`,
                        subtitle: <>
                            {t`Zadaný email už existuje pre člena`}
                            <strong className='underline'>
                                <a
                                    target='_blank'
                                    href={`/members/${response.payload.client_id}`}
                                    rel="noreferrer"> {response.payload.client_name}
                                </a>
                            </strong>
                            {t`. Prajete si povoliť duplicitnú registráciu?`}
                        </>,
                        textButton: t`Potvrdiť`,
                        icon: <IconWarning />,
                        action: () => {
                            resendFormData()
                        }
                    })
                } else if (response.payload !== undefined && response.payload !== null && response.error === undefined) {
                    setDisabledCrossIcon(true)
                    setDataModal({
                        title: t`Prdanie nového člena`,
                        subtitle: t`Nový člen bol úspešne pridaný do systému.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconSuccess />,
                        action: closeWindowNavigate
                    })
                } else if (response.payload.status === 'birthday_error') {
                    setDataModal({
                        title: t`Člena nie je možné zaregistrovať`,
                        subtitle: t`Registrácia nie je povolená pre osoby mladšie ako 18 rokov.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                } else {
                    setDataModal({
                        title: t`Chyba`,
                        subtitle: t`Nastala chyba pri pridávaní nového člena.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                }
            })
    }
    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                disabledCrossIcon={disabledCrossIcon}
                disabledButton={disabledButton}
                closeWindow={closeWindow}
            >
                <div className='absolute  left-1/2 transform -translate-x-1/2 bottom-2'>
                    <Loader loading={loading} />
                </div>
            </ModalWindow>

            <div className='bg-app'>
                <div className='lg:mx-[50px] lg:mt-9 lg:mb-10'>
                    <FormCodeVerification hotels={hotels} />
                </div>
                <div className='border-b-2 border-main-brown border-opacity-15 lg-max:hidden'></div>
                <div>
                    <div className=' mt-10 lg:mx-[50px]  lg:mb-14 text-center bg-white lg-max:rounded-20 lg-max:pt-[22px] lg-max:pb-8 lg-max:px-[25px]'>
                        <h4 className='uppercase md:text-base text-[11px] text-main-brown font-montserrat-bold pb-15 tracking-1.6'><Trans>pridanie nového člena</Trans></h4>
                        <p className='md:text-11 text-[10px] text-main-brown pb-8'><Trans>Vyplňte údaje o členovi</Trans></p>
                        <FormAddNewMember
                            hotels={hotels}
                            hnadleSubmitFormData={hnadleSubmitFormData}
                        />
                    </div>
                </div>
                <div className='flex justify-center mt-4'>
                    <Loader loading={loading} />
                </div>

            </div>
        </>
    )
}

export default PageHome
